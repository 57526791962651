import React from "react"
import { Card, Row, Col } from "antd"
import moment from "moment"
import CheckBox from "../../../components/check-box/CheckBox"
import EditService from "../../../components/edit-service/EditService"
import { EditOutlined } from "@ant-design/icons"
import TicketSubmit from "../../../components/ticketSubmit/TicketSubmit"
import { usaFormatNumber } from "../../../utils/helper"
const FoodCertificate = props => {
  const {
    serviceCheck,
    lastKnown,
    from,
    washDate,
    ticketWashClose,
    ticketWashSubmit,
    onChangeTicket,
    load,
    ticketWashEdit,
    certificateLogo,
    serviceData,
    clientName,
    invoice,
    ticketShow,
    edit,
    error,
    ticket,
    nextTicket,
    ref,
    reference,
  } = props
  const certificateImag = `https://backend-demo.washtrax.com/user/image-content?path=${certificateLogo}`
  return (
    <Card>
      <div ref={reference}>
        <div className="page-header" style={{ textAlign: "center" }}>
          <div
            className="client-header d-flex justify-content-center"
            style={{ padding: "10px 23px 23px 23px", alignItems: "center" }}
          >
            <div className="client-logo-certificate">
              <img src={certificateImag || ""} alt="invoice_logo" />
            </div>
            <span style={{ fontSize: 36, fontWeight: 500 }}>
              {invoice?.client_details.company_name}
            </span>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <td>
                {/* <!--place holder for the fixed-position header--> */}
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                {/* <!--*** CONTENT GOES HERE ***--> */}
                <div className="page">
                  <Row gutter={24} className="mt-2">
                    <Col span={8}>
                      <div className="mt-1 font-size-16">
                        <span className="certificate-bold">Customer:</span>{" "}
                        {invoice?.customer_details.name}
                      </div>
                    </Col>
                    <Col span={8} className="mt-1 font-size-16">
                      <div>
                        <span className="certificate-bold">
                          Last Contained:
                        </span>{" "}
                        {(invoice?.asset_txns_details.last_known ?? "NA") +
                          "|" +
                          (invoice?.asset_txns_details.last_known_2 ?? "NA") +
                          " | " +
                          (invoice?.asset_txns_details.last_known_3 ?? "NA")}
                      </div>
                    </Col>
                    <Col span={8} className="mt-1 font-size-16">
                      <div>
                        <span className="certificate-bold">
                          Representative:
                        </span>{" "}
                        {invoice?.client_details?.billing_first_name +
                          " " +
                          invoice?.client_details?.billing_last_name}
                      </div>
                    </Col>
                    <Col span={8} className="mt-1 font-size-16">
                      <div>
                        <span className="certificate-bold">
                          Trailer Unit Number:
                        </span>{" "}
                        {invoice?.asset_details.equipment_no}
                      </div>
                    </Col>
                    <Col span={8} className="mt-1 font-size-16">
                      <div>
                        <span className="certificate-bold">
                          Billing Address:
                        </span>{" "}
                        {invoice?.client_details.billing_address +
                          ", " +
                          invoice?.client_details.billing_city +
                          ", " +
                          invoice?.client_details.billing_state +
                          ", " +
                          invoice?.client_details.billing_zipcode}
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="mt-1 font-size-16">
                        <span className="certificate-bold">
                          Wash Certificate Number:{" "}
                        </span>
                        {ticketShow ? nextTicket : ""}
                        {ticketShow ? (
                          <div
                            style={{ cursor: "pointer" }}
                            className="wash_certificate_ticket_edit"
                            onClick={ticketWashEdit}
                          >
                            {edit ? <EditOutlined /> : ""}
                          </div>
                        ) : (
                          <TicketSubmit
                            load={load}
                            type="text"
                            name="ticket"
                            value={nextTicket === "NA" ? "" : nextTicket}
                            onChange={e => onChangeTicket(e)}
                            ticketWashSubmit={() => ticketWashSubmit()}
                            ticketWashClose={ticketWashClose}
                            error={error}
                            btnshow={nextTicket === ticket || nextTicket === ""}
                          />
                        )}
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="mt-1 font-size-16">
                        <span className="certificate-bold">Truck Number:</span>{" "}
                        {invoice?.asset_txns_details.truck_number}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mt-1 font-size-16">
                        <span className="certificate-bold">Date of Wash:</span>{" "}
                        {moment(washDate).format("MM/DD/YYYY, hh:mm A")}{" "}
                      </div>
                    </Col>
                  </Row>

                  <div className="content-block mt-3 food-table">
                    <table
                      style={{ width: "100%" }}
                      className="table center food-table-bordered table-hover"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "22%" }}></th>
                          <th style={{ width: "15%" }}>Duration</th>
                          <th style={{ width: "15%" }}>Temp f&#176;</th>
                          <th style={{ width: "18%" }}>Final Conductivity</th>
                          <th style={{ width: "15%" }}>Flow GPM</th>
                          <th style={{ width: "15%" }}>Total Flow</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice &&
                          invoice.sub_tickets.map(ele => (
                            <tr>
                              <td>{ele.services.param_description ?? "NA"}</td>
                              <td>{ele.duration ?? "NA"}</td>
                              <td>{ele.temp ?? "NA"}</td>
                              <td>{ele.final_conductivity ?? "NA"}</td>
                              <td>{ele.flow_gpm ?? "NA"}</td>
                              <td>{ele.total_flow ?? "NA"}</td>
                            </tr>
                          ))}
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan="6">
                            Install Safety Seals & Record Below
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice?.ticket_details.map(
                          ele =>
                            ele.field_name === "safety_records" && (
                              <tr>
                                <td>Tag # ONLMO11 (Enter last 3)</td>
                                <td>
                                  <img width="100%" src={ele.field_value} />
                                </td>
                                <td colSpan="2">{ele.misc}</td>
                                <td colSpan="2">{ele.remark}</td>
                              </tr>
                            )
                        )}
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan="3">
                            Internal Visual & Final by Technician
                          </th>
                          <th colSpan="3">
                            Inspected and Accepted by Customer Representative
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Signature:</td>
                          <td colSpan="2"></td>
                          <td>Signature:</td>
                          <td colSpan="2"></td>
                        </tr>
                        <tr>
                          <td>Date:</td>
                          <td colSpan="2"></td>
                          <td>Date:</td>
                          <td colSpan="2"></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mt-3">
                      <p style={{ textAlign: "center", padding: 10 }}>
                        American Tank Wash Partners, LLC does not guarantee or
                        warranty (expressed or implied) any quality or presence
                        of foreign material, moisture, chemicals, residue,
                        contaminants or any other defects of any kind following
                        the cleaning services performed. The inspection of the
                        customer representative or designee accepting the
                        equipment agree and acknowledge that such tank and
                        related equipment are cleaned and acceptable for their
                        next use. The customer agrees that American Tank Wash
                        Partners, LLC shall not be responsible for any loss or
                        damage to the customers tank or related equipment while
                        on American Tank Wash Partners, LLC premises (leased or
                        owned). REV 2020-01-02
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <style>{`
           @page {
            margin: 10mm
          } 
  `}</style>
      </div>
    </Card>
  )
}
export default FoodCertificate
