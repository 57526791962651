import React from 'react'
import "./check.css"
const CheckBox = ({type,name,value,onChange,service,checked}) => {
    return (
        <div className="wash_check_box">
            <input type={type} name={name} value={value} checked={checked} onChange={onChange} />
            <label htmlFor={service}>{ service}</label>
        </div>
    )
}

export default CheckBox
